<template>
  <div class="contractdetail">
    <el-row :gutter="2">
      <el-col :span="8">
        <div class="grid-content">
          <span class="label">甲方单位: </span>
          <span class="value">{{ contractDetail.first_party }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content">
          <span class="label">合同编号: </span>
          <span class="value">{{ contractDetail.contract_id }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content">
          <span class="label">合同名称: </span>
          <span class="value">{{ contractDetail.contract_name }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="4">
        <div class="grid-content">
          <span class="label">合同金额: </span>
          <span class="value">{{ contractDetail.contract_amount }}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content">
          <span class="label">施工材料: </span>
          <span class="value">{{ contractDetail.construction_material }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content">
          <span class="label">施工地点: </span>
          <span class="value">{{ contractDetail.project }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="8">
        <div class="grid-content">
          <span class="label">乙方单位: </span>
          <span class="value">{{ contractDetail.second_party }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content">
          <span class="label">乙方签合同人: </span>
          <span class="value">{{ contractDetail.contact }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content">
          <span class="label">工地现有钢板桩总数（片）: </span>
          <span class="value">000</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    contractDetail: Object,
    userType: Object
  }
}
</script>
